export const LANDING = '/';
export const LEGAL = '/nota-legal';
export const PRIVACY = '/politica-de-privacidad';
export const COOKIES = '/cookies';

export const CARGADORES = '/cargadores-coches-electricos';
export const DOMESTICO = '/electricistas-barcelona';
export const INDUSTRIAL = '/instalaciones-electricas-industriales';
export const MANTENIMIENTO = '/mantenimiento-y-averias-electricas';
export const AIRE = '/instalacion-aire-acondicionado';
export const CARGADOR = (slug) => `/cargadores-coches-electricos/${slug}`;

export const PRESUPUESTO = '/presupuesto';